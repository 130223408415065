import { useContext, useState } from "react";
import {
  Form,
  Select,
  Switch,
  Checkbox,
  Slider,
  Row,
  Col,
  Typography,
  Divider,
  Radio,
  Alert,
  Tooltip,
} from "antd";
import _set from "lodash/set";
import _get from "lodash/get";
import Axios from "axios";
import styled from "styled-components";
import {
  CloseOutlined,
  CheckOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { WidgetSettingsContext } from "./context";
import TimeConfigModule from "./TimeConfigModule";
import UrlList from "./UrlList";
import useAppContext from "../../modules/context/AppContext";

const { Option } = Select;
const CheckboxGroup = Checkbox.Group;
const { Title } = Typography;
const PopularTagExample = styled.div`
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  margin: 5px 0;
  padding: 10px;
  font-size: 12px;
`;
const RadioGroup = Radio.Group;
const WidgetInfo = ({ settings, index, permission, pipelineData }) => {
  const { activeSite, sites } = useAppContext();
  const defaultSettings = Object.assign(
    {
      date_time_config: {
        day: { enabled: false, value: ["mon"] },
        time: { enabled: false, value: [] },
        date: { enabled: false, value: [] },
      },
      search_context: {
        enabled: false,
        search_in: [],
        search_from: [],
        keywords: [],
        exclude_keywords: [],
      },
      search_tags: {
        enabled: false,
        type: "none",
        article_includes: [],
        article_excludes: [],
        includes: [],
        excludes: [],
        popular_tags: [],
        popular_tags_section: [],
      },
      search_category: {
        enabled: false,
        includes: [],
        excludes: [],
      },
      with_stats: {
        most_click: false,
        relate_click: false,
      },
      channel: {
        site: [],
        query: [],
      },
      content_url: [],
      filter_device: [],
      age: [0, 7],
      limit: [1, 4],
      show_limit: 4,
      random: false,
      include_content_id: [],
      exclude_content_id: [],
      site: [activeSite],
    },
    settings
  );

  const [popularTag, setPopularTags] = useState([]);
  const [toggleCategoryHelp, setToggleCategoryHelp] = useState(false);
  const [selectedOption, setSelectedOption] = useState("mon-thu");
  const {
    handleChangeSettings,
    settingData: { relate_article },
  } = useContext(WidgetSettingsContext);
  const [widget, setWidget] = useState(defaultSettings);

  const pipeline_content_id = pipelineData.filter(
    (pipline) => pipline.field_type === "content_id"
  );

  const pipeline_tags = pipelineData.filter(
    (pipline) => pipline.field_type === "tags"
  );

  const pipeline_category = pipelineData.filter(
    (pipline) => pipline.field_type === "category"
  );

  const pipeline_keywords = pipelineData.filter(
    (pipline) => pipline.field_type === "keywords"
  );

  const onFieldsChange = (field, value) => {
    const _data = { ...widget };
    _set(_data, field, value);
    setWidget(_data);
    handleChangeSettings(index, _data);
  };

  const getPopularTags = async (sections) => {
    const {
      data: { data },
    } = await Axios.post(
      `${process.env.REACT_APP_API_URL}/v1.0/widget/popular`,
      { sections },
      {
        withCredentials: true,
      }
    );
    setPopularTags(data);
  };

  const onAiBehevChange = async (field, value) => {
    const _data = { ...widget };
    if (value === true) {
      _data.is_aiBeh = true;
    } else {
      _data.is_aiBeh = false;
    }

    _set(_data, field, value);
    setWidget(_data);
    handleChangeSettings(index, _data);
  };
  return (
    <div>
      {console.log("widget", widget)}
      <Form labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
        <Divider dashed orientation="left">
          <Title level={4}>ตั้งค่าค้นหาด้วยคำค้นหา</Title>
        </Divider>
        <Form.Item label="เปิดใช้งาน" style={{ marginBottom: 0 }}>
          <Switch
            disabled={permission}
            size="small"
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            onChange={(value) =>
              onFieldsChange("search_context.enabled", value)
            }
            key="text-search"
            checked={widget.search_context.enabled}
          />
        </Form.Item>
        {widget.search_context.enabled && (
          <div
            style={{
              border: "1px solid #ddd",
              padding: 5,
              borderRadius: 5,
            }}
          >
            <Form.Item label="ค้นหาใน">
              <CheckboxGroup
                disabled={permission}
                options={["หัวข่าว", "คำโปรย", "เนื้อหา", "แท็ก"]}
                value={widget.search_context.search_in}
                onChange={(value) => {
                  onFieldsChange("search_context.search_in", value);
                }}
              />
            </Form.Item>
            {relate_article && (
              <Form.Item label="ใช้คำค้นจาก">
                <CheckboxGroup
                  disabled={permission}
                  options={["หัวข้อ", "คำโปรย", "แท็ก"]}
                  value={widget.search_context.search_from}
                  onChange={(value) => {
                    onFieldsChange("search_context.search_from", value);
                  }}
                />
                {relate_article && _get(widget, "search_context.search_from", []).includes("แท็ก") && (
                  <div style={{ marginTop: 5 }}>
                    <CheckboxGroup
                      options={["Tag 1", "Tag 2", "Tag 3", "Tag 4", "Tag 5", "Tag 6", "Tag 7", "Tag 8", "Tag 9", "Tag 10"]}
                      value={widget.search_context.tags}
                      onChange={(value) => {
                        onFieldsChange("search_context.tags", value);
                      }}
                    />
                  </div>
                )}
              </Form.Item>
            )}
            <Form.Item label="เจาะจงคำค้นหา">
              <Select
                mode="tags"
                placeholder="ระบุ keyword (optional)"
                value={widget.search_context.keywords}
                onChange={(value) => {
                  onFieldsChange("search_context.keywords", value);
                }}
              >
                {pipeline_tags.map((item) => (
                  <Option key={`pipeline[${item.id}]`}>
                    Pipeline: {item.name}
                  </Option>
                ))}
                {pipeline_keywords.map((item) => (
                  <Option key={`pipeline[${item.id}]`}>
                    Pipeline: {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item label="exclude คำ">
              <Select
                mode="tags"
                style={{ width: "100%" }}
                placeholder="ระบุ keyword (optional)"
                onChange={(value) => {
                  onFieldsChange("search_context.exclude_keywords", value);
                }}
                value={widget.search_context.exclude_keywords}
              >
                {pipeline_tags.map((item) => (
                  <Option key={`pipeline[${item.id}]`}>
                    Pipeline: {item.name}
                  </Option>
                ))}
                {pipeline_keywords.map((item) => (
                  <Option key={`pipeline[${item.id}]`}>
                    Pipeline: {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
        )}

        <Divider dashed orientation="left">
          <Title level={4}>ตั้งค่าค้นหาด้วยแท็ก</Title>
        </Divider>
        <Form.Item label="เปิดใช้งาน" style={{ marginBottom: 0 }}>
          <Switch
            disabled={permission}
            size="small"
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            onChange={(value) => onFieldsChange("search_tags.enabled", value)}
            key="text-search"
            checked={widget.search_tags.enabled}
          />
        </Form.Item>
        {widget.search_tags.enabled && (
          <div
            style={{
              border: "1px solid #ddd",
              padding: 5,
              borderRadius: 5,
            }}
          >
            {relate_article && (
              <Form.Item label="ใช้คำค้นจากข่าว">
                <Radio.Group
                  value={widget.search_tags.type}
                  onChange={(e) => {
                    onFieldsChange("search_tags.type", e.target.value);
                  }}
                >
                  <Radio value={"none"}>ไม่เลือก</Radio>
                  <Radio value={"all"}>แท็กทั้งหมด</Radio>
                  <Radio value={"some_tags"}>บางแท็ก</Radio>
                  <Radio value={"tag_significant"}>แท็กที่เกี่ยวข้อง</Radio>
                </Radio.Group>
                {_get(widget, "search_tags.type", []).includes("some_tags") && (
                  <CheckboxGroup
                    options={["Tag 1", "Tag 2", "Tag 3", "Tag 4", "Tag ...n"]}
                    value={widget.search_tags.article_includes}
                    onChange={(value) => {
                      onFieldsChange("search_tags.article_includes", value);
                    }}
                  />
                )}
              </Form.Item>
            )}
            {relate_article && (
              <Form.Item label="exclude แท็กจากข่าว">
                <CheckboxGroup
                  options={[
                    "Tag 1",
                    "Tag 2",
                    "Tag 3",
                    "Tag 4",
                    "Tag 5",
                    "Tag 6",
                    "Tag 7",
                    "Tag 8",
                    "Tag 9",
                    "Tag 10",
                    "Tag ...n",
                  ]}
                  value={widget.search_tags.article_excludes}
                  onChange={(value) => {
                    onFieldsChange("search_tags.article_excludes", value);
                  }}
                />
              </Form.Item>
            )}
            <Form.Item label="include">
              <Select
                mode="tags"
                style={{ width: "100%" }}
                placeholder="tag"
                onChange={(value) => {
                  onFieldsChange("search_tags.includes", value);
                }}
                value={widget.search_tags.includes}
              >
                {pipeline_tags.map((item) => (
                  <Option key={`pipeline[${item.id}]`}>
                    Pipeline: {item.name}
                  </Option>
                ))}
                {pipeline_keywords.map((item) => (
                  <Option key={`pipeline[${item.id}]`}>
                    Pipeline: {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="exclude">
              <Select
                mode="tags"
                style={{ width: "100%" }}
                placeholder="tag"
                onChange={(value) => {
                  onFieldsChange("search_tags.excludes", value);
                }}
                value={widget.search_tags.excludes}
              >
                {pipeline_tags.map((item) => (
                  <Option key={`pipeline[${item.id}]`}>
                    Pipeline: {item.name}
                  </Option>
                ))}
                {pipeline_keywords.map((item) => (
                  <Option key={`pipeline[${item.id}]`}>
                    Pipeline: {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            {/* deprecated. use pipeline instead
            <Form.Item label="popular tags จาก">
              <Select
                mode="tags"
                style={{ width: "100%" }}
                onChange={(e) => {
                  onFieldsChange("search_tags.popular_tags_section", e);
                  getPopularTags(e);
                }}
                value={widget.search_tags.popular_tags_section}
              >
                {["home", "news", "business", "entertain"].map((item) => (
                  <Option key={item}>{item}</Option>
                ))}

                {pipeline_tags.map((item) => (
                  <Option key={`pipeline[${item.id}]`}>
                    Pipeline: {item.name}
                  </Option>
                ))}
                {pipeline_keywords.map((item) => (
                  <Option key={`pipeline[${item.id}]`}>
                    Pipeline: {item.name}
                  </Option>
                ))}
              </Select>
              {_get(widget, "search_tags.popular_tags_section", []).length >
                0 && (
                <CheckboxGroup
                  options={[
                    "Tag 1",
                    "Tag 2",
                    "Tag 3",
                    "Tag 4",
                    "Tag 5",
                    "Tag 6",
                    "Tag 7",
                    "Tag 8",
                    "Tag 9",
                    "Tag 10",
                    "Tag ...n",
                  ]}
                  value={widget.search_tags.popular_tags}
                  onChange={(value) => {
                    onFieldsChange("search_tags.popular_tags", value);
                  }}
                />
              )}
              {popularTag.map((item, i) => (
                <PopularTagExample key={i}>
                  <div>popular tags หน้า {item.section} ปัจจุบัน</div>
                  {item.tags.map((tag) => (
                    <Tag>{tag}</Tag>
                  ))}
                </PopularTagExample>
              ))}
            </Form.Item>{" "}
            */}
          </div>
        )}

        <Divider dashed orientation="left">
          <Title level={4}>
            ตั้งค่าหมวดหมู่{" "}
            <QuestionCircleOutlined
              onClick={() => {
                setToggleCategoryHelp(!toggleCategoryHelp);
              }}
            />
          </Title>
        </Divider>
        {toggleCategoryHelp && (
          <Alert
            message={
              <>
                https://www.thairath.co.th/
                <b style={{ color: "red" }}>news/foreign</b> ใส่{" "}
                <b style={{ color: "red" }}>news/foreign</b>
                <br />
                https://www.thairath.co.th/
                <b style={{ color: "red" }}>news/crime</b>/2330306 ใส่{" "}
                <b style={{ color: "red" }}>news/crime</b>
                <br />
                https://www.thairath.co.th/
                <b style={{ color: "red" }}>video/news/topnews/hotclip</b>
                /1234567 ใส่{" "}
                <b style={{ color: "red" }}>video/news/topnews/hotclip</b>
                <br />
                https://plus.thairath.co.th/
                <b style={{ color: "red" }}>topic/speak</b>/1234567 ใส่{" "}
                <b style={{ color: "red" }}>topic/speak</b>
                <br />
                https://plus.thairath.co.th/
                <b style={{ color: "red" }}>topic/money</b> ใส่{" "}
                <b style={{ color: "red" }}>topic/money</b>
                <br />
                https://plus.thairath.co.th/
                <b style={{ color: "red" }}>category/global-affairs</b> ใส่{" "}
                <b style={{ color: "red" }}>category/global-affairs</b>
              </>
            }
            type="info"
          />
        )}

        <Form.Item label="AI Behavior" style={{ marginBottom: 0 }}>
          <Switch
            disabled={permission}
            size="small"
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            onChange={(value) => onAiBehevChange("search_ai.enabled", value)}
            key={"switch-ai"}
            checked={widget.search_ai ? widget.search_ai.enabled : false}
            defaultChecked
          />
        </Form.Item>

        {widget.search_ai && widget.search_ai.enabled === true ? (
          ""
        ) : (
          <>
            {relate_article && (
              <Form.Item
                label="ใช้หมวดหมู่เดียวกับข่าว"
                style={{ marginBottom: 0 }}
              >
                <Switch
                  disabled={permission}
                  size="small"
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  onChange={(value) =>
                    onFieldsChange("search_category.enabled", value)
                  }
                  key={"switch-category"}
                  checked={widget.search_category.enabled}
                  defaultChecked
                />
              </Form.Item>
            )}
            <Form.Item label="include">
              <Select
                disabled={permission}
                mode="tags"
                style={{ width: "100%" }}
                placeholder="news/local/bangkok"
                onChange={(e) => {
                  onFieldsChange("search_category.includes", e);
                }}
                value={widget.search_category.includes}
              >
                {(widget.search_category.includes || []).map((item) => (
                  <Option key={item}>{item}</Option>
                ))}

                {pipeline_category.map((item) => (
                  <Option key={`pipeline[${item.id}]`}>
                    Pipeline: {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="exclude">
              <Select
                disabled={permission}
                mode="tags"
                style={{ width: "100%" }}
                placeholder="news/local/bangkok"
                onChange={(e) => {
                  onFieldsChange("search_category.excludes", e);
                }}
                value={widget.search_category.excludes}
              >
                {(widget.search_category.excludes || []).map((item) => (
                  <Option key={item}>{item}</Option>
                ))}
                {pipeline_category.map((item) => (
                  <Option key={`pipeline[${item.id}]`}>
                    Pipeline: {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </>
        )}

        <Divider dashed orientation="left">
          <Title level={4}>นำ stats มาคำนวน</Title>
        </Divider>

        <Form.Item
          label={
            <Tooltip title="content ที่ถูกคลิ๊กมากที่สุด">Most Click ?</Tooltip>
          }
          style={{ marginBottom: 0 }}
        >
          <Switch
            disabled={permission}
            size="small"
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            onChange={(value) => onFieldsChange("with_stats.most_click", value)}
            key={"switch-category"}
            checked={widget.with_stats.most_click}
            defaultChecked
          />
        </Form.Item>
        {relate_article && (
          <Form.Item
            label={
              <Tooltip title="content ที่ถูกคลิ๊กมากที่สุด โดยจะ relate กับ content ที่ user กำลังอ่าน">
                Most Relate Click ?
              </Tooltip>
            }
            style={{ marginBottom: 0 }}
          >
            <Switch
              disabled={permission}
              size="small"
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              onChange={(value) =>
                onFieldsChange("with_stats.relate_click", value)
              }
              key={"switch-category"}
              checked={widget.with_stats.relate_click}
              defaultChecked
            />
          </Form.Item>
        )}
        <Divider dashed orientation="left">
          <Title level={4}>ตั้งค่าวัน/เวลา</Title>
        </Divider>
        <TimeConfigModule
          widget={widget}
          onFieldsChange={onFieldsChange}
          permission={permission}
        />
        <Divider dashed orientation="left">
          <Title level={4}>ตั้งค่าทั่วไป</Title>
        </Divider>

        <Form.Item label="Channel">
          <Select
            disabled={permission}
            mode="tags"
            style={{ width: "100%" }}
            onChange={(value) => {
              onFieldsChange("channel.site", value);
            }}
            value={widget.channel.site}
          >
            <Option key="facebook" value="facebook">
              facebook
            </Option>
            <Option key="google" value="google">
              google
            </Option>
            <Option key="query" value="query">
              query (เช่น ?utm_source=query)
            </Option>
          </Select>
        </Form.Item>
        <Form.Item label="ระบุ Device">
          <Select
            disabled={permission}
            mode="tags"
            style={{ width: "100%" }}
            onChange={(value) => {
              onFieldsChange("filter_device", value);
            }}
            value={widget.filter_device}
          >
            <Option key="desktop" value="desktop">
              Desktop
            </Option>
            <Option key="mobile" value="mobile">
              Mobile
            </Option>
            <Option key="tablet" value="tablet">
              Tablet
            </Option>
          </Select>
        </Form.Item>

        {_get(widget, "channel.site", []).includes("query") && (
          <Form.Item label="Query">
            <Select
              disabled={permission}
              mode="tags"
              style={{ width: "100%" }}
              placeholder="utm_source="
              onChange={(e) => {
                onFieldsChange("channel.query", e);
              }}
              value={widget.channel.query}
            >
              {(widget.channel.query || []).map((item) => (
                <Option key={item}>{item}</Option>
              ))}
            </Select>
          </Form.Item>
        )}
        <Form.Item label="ระบุเว็บไซต์">
          <Select
            disabled={permission}
            mode="tags"
            style={{ width: "100%" }}
            onChange={(value) => {
              onFieldsChange("site", value);
            }}
            // defaultValue={!widget.site || [activeSite]}
            value={
              widget.site && widget.site.length > 0 ? widget.site : [activeSite]
            }
          >
            {sites.map((item) => (
              <Option key={"site-" + item.slug} value={item.slug}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="ระบุประเภท content">
          <Select
            disabled={permission}
            mode="tags"
            style={{ width: "100%" }}
            onChange={(value) => {
              onFieldsChange("contentType", value);
            }}
            value={widget.contentType}
          >
            <Option key="content" value="content">
              Article
            </Option>
            <Option key="video" value="video">
              Video Program
            </Option>
            <Option key="tv" value="tv">
              Video TV
            </Option>
          </Select>
        </Form.Item>

        <Form.Item label="Exclude content">
          <Select
            disabled={permission}
            mode="tags"
            style={{ width: "100%" }}
            placeholder="3020123"
            onChange={(e) => {
              onFieldsChange("exclude_content_id", e);
            }}
            value={widget.exclude_content_id}
          >
            {(widget.exclude_content_id || []).map((item) => (
              <Option key={item}>{item}</Option>
            ))}
            {(pipeline_content_id || []).map((item) => (
              <Option key={`pipeline[${item.id}]`}>
                Pipeline: {item.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="include content">
          <Select
            disabled={permission}
            mode="tags"
            style={{ width: "100%" }}
            placeholder="3020123"
            onChange={(e) => {
              onFieldsChange("include_content_id", e);
            }}
            value={widget.include_content_id}
          >
            {(widget.include_content_id || []).map((item) => (
              <Option key={item}>{item}</Option>
            ))}

            {(pipeline_content_id || []).map((item) => (
              <Option key={`pipeline[${item.id}]`}>
                Pipeline: {item.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="url content">
          <UrlList widget={widget} onFieldsChange={onFieldsChange}></UrlList>
        </Form.Item>
        <Form.Item label="Age">
          <Row>
            <Col span={16}>
              <Slider
                disabled={permission}
                range
                min={0}
                max={120}
                defaultValue={widget.age}
                onAfterChange={(value) => {
                  onFieldsChange("age", value);
                }}
              />
            </Col>
            <Col span={4} style={{ paddingTop: 5, textAlign: "right" }}>
              <span>
                {widget.age[0]}-{widget.age[1]} วัน
              </span>
            </Col>
          </Row>
        </Form.Item>
        <Form.Item label="จำนวนข่าวที่ค้นหา">
          <Row>
            <Col span={16}>
              <Slider
                disabled={permission}
                range
                min={1}
                max={100}
                defaultValue={widget.limit}
                onAfterChange={(value) => {
                  onFieldsChange("limit", value);
                }}
              />
            </Col>
            <Col span={4} style={{ paddingTop: 5, textAlign: "right" }}>
              <span>
                {widget.limit[0]}-{widget.limit[1]}
              </span>
            </Col>
          </Row>
        </Form.Item>
        <Form.Item label="จำนวนข่าวที่แสดงจริง">
          <Row>
            <Col span={16}>
              <Slider
                disabled={permission}
                min={1}
                max={100}
                defaultValue={widget.show_limit}
                onAfterChange={(value) => {
                  onFieldsChange("show_limit", value);
                }}
              />
            </Col>
            <Col span={4} style={{ paddingTop: 5, textAlign: "right" }}>
              <span>{widget.show_limit}</span>
            </Col>
          </Row>
        </Form.Item>
        <Form.Item label="เรียงจาก">
          <Select
            disabled={permission}
            mode="tags"
            style={{ width: "100%" }}
            onChange={(value) => {
              onFieldsChange("sort", value);
            }}
            value={widget.sort}
          >
            <Option key="relevance" value="relevance">
              relevance
            </Option>
            {/* <Option key="views" value="views">
              ยอดวิวสูงสุด (ยอดวิว delay 2 วัน)
            </Option> */}
            <Option key="daily_views" value="daily_views">
              ยอดวิวรายวัน
            </Option>
            {/* <Option key="realtime_views" value="realtime_views">
              ยอดวิวสูงสุดใน 30 นาที (อัพเดททุกๆ 5 นาที)
            </Option> */}
            <Option key="date" value="date">
              วันที่เผยแพร่ล่าสุด
            </Option>
          </Select>
        </Form.Item>
        <Form.Item label="สุ่มข่าว">
          <Switch
            disabled={permission}
            size="small"
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            checked={widget.random}
            onChange={(value) => {
              onFieldsChange("random", value);
            }}
          />
        </Form.Item>
      </Form>
    </div>
  );
};

export default WidgetInfo;
